import React from 'react'
import Path from './Routing/Path'


const App = () => {
  return (
    <div>
      <Path/>
    </div>
  )
}

export default App